import React from 'react';
import { withRouter } from "react-router-dom";

import aboutUs from './../assets/img/about-us.png';
import aboutUs1 from './../assets/img/about-us-1.jpg';
import aboutUs2 from './../assets/img/about-us-2.jpg';
import aboutUs3 from './../assets/img/about-us-3.jpg';
import aboutUsMob from './../assets/img/about-us-mob.png';


class About extends React.Component {

    componentDidMount(){
        document.title ="About Us / The Gärten";
    }

    renderAboutPageSections = (title, text, img) =>{
        return <div className="section-desc-container about-section-desc-container">
            <h1>{title}</h1>
            <div className="about-desc-wrapper">
                <div className="about-desc-text">{text}</div>
                <div className="about-desc-img">
                    <img src={img} alt="section-img" className="desktop-only"/>
                </div>
            </div>
        </div>
    }

    render (){
        return(
            <div className="about-page">
                <div className="section-image head-img"></div>
                <div className="black-bg"></div>
                <div className="page-gradiant">
                    <img className="desktop-only" src = {aboutUs} alt="inside-look"/>
                    <img className="mob-only" src = {aboutUsMob} alt="inside-look"/>
                </div>
                {this.renderAboutPageSections(
                    'The GÄrten',
                    <>
                        <p> The Gärten is a state of the art curated event space, with an imposing central event space. The surrounding areas are divided into micro-spaces that offer a variety of experiences and pleasures to enjoy in small intimate groups or in a bottleneck of bodies. </p>
                        <p className="p-lg">At the heart of the venue is the mothership- a luminescent structure that glows according to the mood inside. </p>
                        <p> Surrounding the warm glow of her body is a dense forest adorned with spaces of encounter: art works, live residencies, cafe areas and listening booths. A concept store and some chilling areas makeup the remainder of this city sanctuary.  </p>
                        <img src={aboutUs1} alt="section-img" className="mob-only"/>
                        <p className="p-lg"> The program is diverse, showcasing from a wide spectrum of talent and performance. </p>
                        <p> The venue is multipurpose and transforms according to the spectacle inside. From high-end, glamorous music and entertainment productions to unusual art installations and exhibitions, special showcases, fashion shows and culinary and wellness experiences.  </p>
                        <p> Miami is home to some of the coolest festivals in the country, bringing in people from all over. Our satellite events will offer alternative views for local and visiting crowds to dive into. </p>
                    </>,
                    aboutUs1
                )}

                <div className="section-image width-100 middle-img"></div>
                <div className="section-gradaint"></div>

                {this.renderAboutPageSections(
                    <>Your New Home At <br/> The Miami Design District</>,
                    <>
                        <p>We are thrilled to announce our partnership with the Miami Design District, where we will build your next outlet for meeting and dissolving in experience. </p>
                        <p>Our partnership with the Miami Design District, a project that has done so much for the changing face of Miami, is one we are confident you will enjoy. Today, with over 100 flagship stores, some of the best architecture in the country and impeccable design showrooms, the Miami Design District vision has been realized. </p>
                        <img src={aboutUs2} alt="section-img" className="mob-only"/>
                        <p>Placing a cultural hotspot that resonates and breathes the personality of its guests, at the very heart of the Miami Design district will align with our city’s exciting future. We look forward to meeting you on our floors, to take part in a future driven by so much excitement, to champion art and culture in a city as historically diverse and expressive as Miami.  </p>
                    </>,
                    aboutUs2
                )}

                <div className="section-image bottom-img"></div>
                <div className="section-gradaint"></div>

                {this.renderAboutPageSections(
                    <>A Project by <br/> Social Engineers</>,
                    <>
                        <p>The project is founded by upstart organisation Social Engineers LLC, an innovation driven enterprise, capitalizing on latest market insights by introducing cutting edge consumer- centric experiences. The company was founded early this year by entrepreneur, investor and mentor Peter Rahal and live entertainment visionary and nightlife legend Tiger Saliba. </p>
                        <p>In its founding ethos, the company plans on disrupting the status quo of live entertainment and cementing a new legacy for cultural experiences in the country.</p>
                        <img src={aboutUs3} alt="section-img" className="mob-only"/>
                    </>,
                    aboutUs3
                )}
            </div>
        );
    }
}

export default withRouter(props => <About {...props}/>);

