import React from 'react';
import { withRouter } from "react-router-dom";
import contactTextImg from './../assets/img/contact-us.png';
import contactTextImgMob from './../assets/img/contact-us-mob.png';


class Contact extends React.Component {

    componentDidMount(){
        document.title ="Contact Us / The Gärten";
    }

    render (){
        return(
            <div className="contact-page">
                <div className="section-image header-img"></div>
                <div className="black-bg"></div>
                <div className="page-gradiant">
                    <img className="desktop-only" src = {contactTextImg} alt="inside-look"/>
                    <img className="mob-only" src = {contactTextImgMob} alt="inside-look"/>
                </div>
         
                <div className="careers-page-content section-desc-container">
                    <h1 className="">GET IN TOUCH</h1>
                    <div className="d-flex careers-page-content-text">
                        <div>
                            <p>Please don’t hesitate to get in touch with our team for any questions you might have regarding the specifics of our space and our plans to bring this exciting new project to Miami. </p>
                            <p>For media requests, partnership openings and other such inquiries, reach out to <a href="mailto:admin@thegarten.live">admin@thegarten.live</a>.</p>
                        </div>
                        <div><p>If you’re just writing to say hello, send us your thoughts and we’ll get back to you in no time! We’re not sure our curators take requests but we promise to pass on your recs. </p></div>
                    
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(props => <Contact {...props}/>);

