import React from 'react';
import {withRouter} from "react-router-dom";

import DesktopHeader from './dektopHeader';
import MobileHeader from './mobileHeader';

class Header extends React.Component {

    render (){
        return (
            <header>
                <DesktopHeader/>
                <MobileHeader/>
            </header>            
        );
    }
}

export default withRouter(props => <Header {...props}/>);
