import React from 'react';
import {Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";


import Header from './components/header/header';
import Footer from './components/footer';
import './App.scss';
import Home from './views/home';
import About from './views/about';
import Careers from './views/careers';
import Contact from './views/contact';
import ScrollToTop from './components/scrollToTop';


class App extends React.Component {

  render () {
    return (
      <Router>
        <ScrollToTop/>
          <Header/>
            <Switch>
              <Route exact path={"/"}>
                <Home/>
              </Route>
              <Route exact path={"/about"}>
                <About/>
              </Route>
              <Route exact path={"/careers"}>
                <Careers/>
              </Route>
              <Route exact path={"/contact-us"}>
                <Contact/>
              </Route>
              <Redirect to="/" />
            </Switch>
          <Footer/>
      </Router>
    );
  }
}

export default App;
