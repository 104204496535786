import React from 'react';
import { withRouter, Link } from "react-router-dom";

class HomePageDesc extends React.Component {
    render (){
        const {title, text, linkImg, linkUrl, className, isReadMoreLeft} =this.props;
        return(
            <div className={className}>
                <div className="section-image"></div>
                <div className="section-gradaint" ></div>
                <div className="section-desc-container">
                    <div className="right-section">
                        <h1>{title}</h1>
                    </div>
                    {(text)? 
                        <div className="left-section">  
                            <p>{text}</p>
                            {!isReadMoreLeft? <Link to={linkUrl}><img src={linkImg} alt="read-more" /></Link>:<></>}
                            
                        </div>
                    :''}
                    {isReadMoreLeft? <Link to={linkUrl}><img src={linkImg} alt="read-more" /></Link>:<></>}
                </div>
               
            </div>
        );
    }
}

export default withRouter(props => <HomePageDesc {...props}/>);

