import React from 'react';
import {withRouter, Link} from "react-router-dom";

import logo from './../../assets/img/logo.svg';


class DesktopHeader extends React.Component {

    render (){
        return (
            <div className="dmenu-container section-desc-container">
                <Link to="/"><img src={logo} alt="logo" /></Link>
                <div className="header-menu-links">
                    <Link to="/">Home</Link>
                    <Link to="/about">About</Link>
                    <Link to="/careers">Careers</Link>
                    <Link to="/contact-us">Contact</Link>
                </div>
            </div>
        );
    }
}

export default withRouter(props => <DesktopHeader {...props}/>);
