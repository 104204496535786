import React from 'react';
import { withRouter } from "react-router-dom";
import HomePageDesc from './../components/HomePageDesc.js';

import homeHeader from './../assets/img/home-page-header.jpg';
import homeFirstImage from './../assets/img/home-page-first-section.jpg';
import homeSecondImage from './../assets/img/home-page-second-section.jpg';
import homeThirdImage from './../assets/img/home-page-third-section.jpg';
import readMore from './../assets/img/read-more-btn.png';
import joinTheTeam from './../assets/img/join-the-team.png';
import insideLook from './../assets/img/inside-look.png';
import insideLookmob from './../assets/img/inside-look-mob.png';
import descImg1 from './../assets/img/home-page-1.jpg';
import descImg2 from './../assets/img/home-page-2.jpg';
import descImg3 from './../assets/img/home-page-3.jpg';
import descImg4 from './../assets/img/home-page-4.jpg';
import descImg5 from './../assets/img/home-page-5.jpg';
import descImg6 from './../assets/img/home-page-6.jpg';


class Home extends React.Component {

    componentDidMount(){
        document.title ="Home / The Gärten";
    }
    
    renderDescriptionSection = () =>{
        const data = [
            {
                title:'Sanctuary',
                text:'The architectural wonder emerges from a dense jungle. A surrounding belt of green encloses the venue. Guests are immersed in a sanctuary of experience.',
                img:descImg1
            },
            {
                title:'Modular',
                text:'Intersecting different artistic fields in a fully purposed, modular production venue. An iconic center of attraction as seen from the sky. ',
                img:descImg2
            },
            {
                title:'Glowing',
                text:'The project is a glowing lantern. As a show is set to begin the building’s color seeps into a luscious red. This hurries the crowd and excites those eager to get inside.',
                img:descImg3
            },
            {
                title:'Gatherings',
                text:'Product launches, Live TV broadcasts, Food and Farmer’s markets, fundraisers and gala dinners and an entire month dedicated to Wellness!',
                img:descImg4
            },
            {
                title:'Culture',
                text:'Fashion shows, performing arts, exhibitions and installations, arthouse cinema nights, audiovisual theater, book readings and panels. All you can ask for from a cultural basin such as ours!',
                img:descImg5
            },
            {
                title:'Entertainment',
                text:'Follow the music. Hosting local, national and international acts of varying genres and degrees of reputation. Quality assured. ',
                img:descImg6
            },
        ]

        return <div className="home-desc-wrapper" >{
            data.map((item,i)=>{
            return <div key={"desc-" + i} className="home-desc-details">
                <img src={item.img} alt="img-1"/>
                <div className="text-wrapper">
                    <h3 className="desc-title">{item.title}</h3>
                    <p className="desc-text">{item.text}</p>
                </div>
            </div>
        })
        }</div>
    }

    render (){
        return(
            <div className="home-page">
                    <HomePageDesc
                        title={<>Gärten<br/>of Pleasure</>}
                        text="The Gärten is a state of the art curated event space, with an imposing central event space. The surrounding areas are divided into micro-spaces that offer a variety of experiences and pleasures to enjoy in small intimate groups or in a bottleneck of bodies."
                        linkImg={readMore}
                        linkUrl="/about"
                        className="garten-of-pleasure-container"
                        sectionImg={homeHeader}
                    />
                    <HomePageDesc
                        title={<>A State of the Art<br/>Curated Event Space</>}
                        className="event-space-container"
                        sectionImg={homeFirstImage}
                    />
                    <div className="home-desc-container">
                        <div className="black-bg"></div>
                        <div className="page-gradiant">
                            <img className="desktop-only" src = {insideLook} alt="inside-look"/>
                            <img className="mob-only" src = {insideLookmob} alt="inside-look"/>
                        </div>
                        <p>The future is being built in front of our eyes. We now spend 70 percent more on experiences and events than we did three decades ago (Eventbrite). We are seeking unique experiences, in spaces that are diverse and reflect multiplicity of uses.</p>
                        {this.renderDescriptionSection()}
                    </div>
                    <HomePageDesc
                        title={<>SOCIAL<br/>ENGINEERS</>}
                        text="The project is founded by upstart organisation Social Engineers LLC, an innovation driven enterprise introducing cutting edge consumer- centric experiences. The company was founded early this year by entrepreneur, investor and mentor Peter Rahal and live entertainment visionary and nightlife legend Tiger Saliba."
                        linkImg={readMore}
                        linkUrl="/about"
                        className="social-engineers-container"
                        sectionImg={homeSecondImage}
                        isReadMoreLeft= {true}
                    />
                    <HomePageDesc
                        title="Hiring Soon!"
                        text="Send us your resumes and portfolios of work for future consideration."
                        linkImg={joinTheTeam}
                        linkUrl="/careers"
                        className="hiring-container"
                        sectionImg={homeThirdImage}
                    />

            </div>
        );
    }
}

export default withRouter(props => <Home {...props}/>);

