import React from 'react';
import { withRouter } from "react-router-dom";
import careersTextImg from './../assets/img/careers.png';
import careersTextImgMob from './../assets/img/careers-mob.png';


class Careers extends React.Component {

    componentDidMount(){
        document.title ="Careers / The Gärten";
    }

    render (){
        return(
            <div className="careers-page">
                <div className="section-image header-img"></div>
                <div className="black-bg"></div>
                <div className="page-gradiant">
                    <img className="desktop-only" src = {careersTextImg} alt="inside-look"/>
                    <img className="mob-only" src = {careersTextImgMob} alt="inside-look"/>
                </div>
         
                <div className="careers-page-content section-desc-container">
                    <h1 className="">Our careers portal is open!</h1>
                    <p>Look out for a listing of open positions in the near future. <a href="https://forms.gle/2hLqJztBSSrrUSjw9" target="_blank">Click here</a> to send us your CV and/or portfolios of work for future consideration.</p>
                    <p>We look forward to hearing from you.</p>
                    <p><span class="tooltip">
                        Liebe Grüße
                        <span class="tooltiptext">German for “best regards”, on the informal side of formal.</span>
                    </span></p>
                </div>
                {/* <div className="section-image middle-img"></div>
                <div className="section-gradaint"></div> */}

          
            </div>
        );
    }
}

export default withRouter(props => <Careers {...props}/>);

