import React from 'react';
import {withRouter, Link} from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';
import logo from './../../assets/img/logo.svg';

class MobileHeader extends React.Component {
    state = {
        menuOpen: false
    }

    handleMenuStateChange (state) {
        this.setState({menuOpen: state.isOpen})  
    }

    closeMenu = () => {
        this.setState({menuOpen: false})
    }

    render (){
        const {menuOpen} = this.state;

        return (
            <div className="mob-menu-container section-desc-container">
                <Link to="/" className="mob-logo"><img src={logo} alt="logo" /></Link>
                <Menu
                    isOpen={menuOpen}
                    onStateChange={(state) => this.handleMenuStateChange(state)}
                    right
                    width={ '100%' }
                    disableAutoFocus
                >
                    <div className="header-menu-links">
                        <Link to="/" onClick={this.closeMenu}>Home</Link>
                        <Link to="/about" onClick={this.closeMenu}>About</Link>
                        <Link to="/careers" onClick={this.closeMenu}>Careers</Link>
                        <Link to="/contact-us" onClick={this.closeMenu}>Contact</Link>
                    </div>
                </Menu>
            </div>
        );
    }
}

export default withRouter(props => <MobileHeader {...props}/>);
