import React from 'react';
import { withRouter, Link } from "react-router-dom";
import logo from './../assets/img/logo.svg';

class Footer extends React.Component {
    render (){
        return(
            <section className="footer footer-container section-desc-container">
                <div className="logo-container ">
                    <Link to="/"><img alt="logo" src={logo} /></Link>
                    <Link to="/#">Terms & Conditions</Link>  
                    <Link to="/#">Privacy Policy</Link>
                </div>

                <div className="footer-menu-links">
                    <Link to="/">Home</Link>
                    <Link to="/about">About</Link>
                    <Link to="/careers">Careers</Link>
                    <Link to="/contact-us">Contact</Link>
                </div>
            </section>
        );
    }
}

export default withRouter(props => <Footer {...props}/>);

